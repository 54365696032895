<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Portfolios | Performers  </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'performers',params:{ Maincatg:'All'}}">Performers</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8" v-for="Data in RespArray" v-bind:key="Data.id">
                <div class="page-title-text">
                    <div class="row"><h5>{{Data.profile_name}}</h5></div><br>
                    <div class="row"><p>{{Data.profile_title}}</p></div><br>
                    <div class="row">
                      <h6 class="text-right"> 
                        <span v-if='Data.catg_main !== ("" || null)'>{{Data.catg_main}}</span>
                        <span v-if='Data.catg1 !== ("" || null)'> | {{Data.catg1}}</span>
                        <span v-if='Data.catg2 !== ("" || null)'> | {{Data.catg2}}</span>
                        <span v-if='Data.catg3 !== ("" || null)'> | {{Data.catg3}}</span> 
                        <span v-if='Data.catg4 !== ("" || null)'> | {{Data.catg4}}</span> 
                        <span v-if='Data.catg5 !== ("" || null)'> | {{Data.catg5}}</span> 
                      </h6>
                    </div>
                </div>
                <div>
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-12 Thira profile-card-5">
                        <div class="card-img-block">
                          <img v-bind:src="Data.profile_photo" alt="Image">
                        </div>
                        <p class="pre-formatted" v-if='Data.profile_description !== ("" || null)' ><span v-html="Data.profile_description"></span></p>    
                    </div>
                  </b-row>  
                </div>  
                <!-- Contact us section starts here  -->
                <div>
                <div class="container">
                    <div class="row">

                    <div class="col-lg-6 col-md-6 contact-card">
                        <h5>Contact Us</h5>
                        <p>
                        <!-- {{Data.academy_address}} <br> -->
                        {{Data.profile_address}}<br><br> 
                        <!-- {{Data.prof_city}}, {{Data.prof_state}}, {{Data.prof_country}} <br><br> -->
                        <strong v-if='Data.profile_cntct1 !== ("" || null)'>Primary No:</strong> {{Data.profile_cntct1}}<br>
                        <strong v-if='Data.profile_cntct2 !== ("" || null)'>Secondary No:</strong> {{Data.profile_cntct2}}<br><br>
                        <strong v-if='Data.profile_email !== ("" || null)'>Email:</strong> {{Data.profile_email}}<br>
                        <strong v-if='Data.profile_website !== ("" || null)'>Website:</strong> <a v-bind:href="Data.profile_website" target="_blank">{{Data.profile_website}}</a><br>
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 contact-card">
                        <h5>Social Profiles</h5>
                        <div class="social-links mt-3">
                        <a class="facebook" v-if='Data.profile_facebook !== ("" || null)' v-bind:href="Data.profile_facebook" target="_blank"><i class="bx bxl-facebook"></i></a>
                        <a class="instagram" v-if='Data.profile_instagram !== ("" || null)' v-bind:href="Data.profile_instagram" target="_blank"><i class="bx bxl-instagram"></i></a>
                        <a class="twitter" v-if='Data.profile_twitter !== ("" || null)' v-bind:href="Data.profile_twitter" target="_blank"><i class="bx bxl-twitter"></i></a>
                        <a class="facebook" v-if='Data.profile_youtube !== ("" || null)' v-bind:href="Data.profile_youtube" target="_blank"><i class="bx bxl-youtube"></i></a>
                        </div>
                    </div>

                    </div>
                </div>
                </div>   
                <!-- Description Tabs starts here  -->
                <div class="contact-card tab-desc-formatted">
                <b-tabs content-class="mt-3">
                    <b-tab title="Programs" active><p class="" v-if='Data.profile_programs !== ("" || null)' ><span v-html="Data.profile_programs"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Trainings"><p class="" v-if='Data.profile_trainings !== ("" || null)' ><span v-html="Data.profile_trainings"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Participations"><p class="" v-if='Data.profile_participations !== ("" || null)' ><span v-html="Data.profile_participations"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                    <b-tab title="Achievements"><p class="" v-if='Data.profile_awards !== ("" || null)' ><span v-html="Data.profile_awards"></span></p><p v-else class="text-danger"> No Info Available! </p></b-tab>
                </b-tabs>
                </div>  
                <!-- Academy Photo Gallery starts here  -->
                <div class="contact-card">
                <b-tabs content-class="mt-3">
                    <b-tab title="My Photo Gallery" active>
                      <div class="container">
                          <b-row>
                            <!-- <div class="col-lg-6 col-md-6 col-sm-6" > -->
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo1 !== ("" || null)' :src="Data.Photo1" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo2 !== ("" || null)' :src="Data.Photo2" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo3 !== ("" || null)' :src="Data.Photo3" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo4 !== ("" || null)' :src="Data.Photo4" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo5 !== ("" || null)' :src="Data.Photo5" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo6 !== ("" || null)' :src="Data.Photo6" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo7 !== ("" || null)' :src="Data.Photo7" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo8 !== ("" || null)' :src="Data.Photo8" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo9 !== ("" || null)' :src="Data.Photo9" fluid-grow :alt="Data.profile_name"></b-img></div>
                            <!-- <div class="col-lg-6 col-md-6"><b-img v-if='Data.Photo10 !== (" " || null)' :src="Data.Photo10" fluid-grow :alt="Data.profile_name"></b-img></div> -->
                            <p v-if='Data.Photo1 == ("" || null)' class="text-danger"> No Photos Available! </p>
                            <!-- </div> -->
                          </b-row>
                      </div>                       
                    </b-tab>
                    <b-tab title="My Videos">
                    <div class="container">
                        <div class="row" >
                        <div class="col-lg-12 col-md-12" >
                          <span v-if='Data.Video1 !== ("" || null)' class="w-100 p-3" v-html="Data.Video1"></span>
                        </div>
                       <div class="col-lg-12 col-md-12">
                          <span v-if='Data.Video2 !== ("" || null)' class="w-100 p-3" v-html="Data.Video2"></span>
                        </div>    
                        <!-- <p v-if='Data.Video1 == ("" || null)' class="text-danger"> No Video Available! Upgrade to premium profile and add two videos from Youtube. <a href="#" class="badge badge-pill badge-success"> Upgrade Now!</a></p> -->
                        <p v-if='Data.Video1 == ("" || null)' class="text-danger"> No Video Available!</p>                      
                      </div> 
                    </div>
                    </b-tab>
                </b-tabs>
                </div>    
                <div class="contact-card">
                  <GoogleAdsense slot="2761789315"/>
                </div>   
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar" v-if="ShowSideCatg">
                <!-- sidebar Categories -->
                <SideCatgPerformers />
                <!-- <Side Recent Performers/> -->
                <SideRecentPerformers :key="componentKey"/>   
                <GoogleAdsense slot="2761789315"/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>              
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer v-if="ShowSideCatg"/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'
import Lightbox from 'vue-my-photos'

Vue.component('lightbox', Lightbox);
Vue.use(Lightbox)

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"PerformingArtist",
    //insert the following code for vue-meta to work
    metaInfo() {
        return {
            title: `${this.MetaName} - ${this.MetaTagline} - Artist Portfolio :: Thiraseela.com`,
            meta: [
                { name: 'description', content: 'Thiraseela.com Artist Portfolios :: ' + this.MetaName + '-' + this.MetaTagline},
                { property: 'og:title', content: this.MetaName + ' - ' + this.MetaTagline + ' - Artist Portfolio :: Thiraseela.com'},
                { property: 'og:site_name', content: 'Thiraseela.com'},
                { property: 'og:description', content: 'Thiraseela.com Artist Portfolios :: ' + this.MetaName + '-' + this.MetaTagline},
                {property: 'og:type', content: 'profile'},
                {property: 'og:url', content: 'https://thiraseela.com/performers-detail/' + this.MetaSeqno + '/' + this.MetaName},
                {property: 'og:image', content: this.MetaUrl }    
            ]
        }
    },   
    data() {
      return {
        RespArray: [],
        RespProfileCatg: [],
        RespProfileCnts: [],
        RelatedData: [],
        RespMedia: [],
        componentKey: 0,
        Arraylimit: 0,
        MetaName: '',
        MetaTagline: '',
        MetaSeqno: '',
        MetaUrl: '',
        ShowSideCatg: true,
        video1: 'https://www.youtube.com/embed/NYwuQg8t8Wk',
        video2: 'https://www.youtube.com/embed/NYwuQg8t8Wk',
        galleryFilter: 'all',
        ImageDir: '',
      // ImageDir: 'http://thiraseela.com/Admin/Content/ImageGallery/61/',
      GallObj: {
        egall_tittle: '',
        egall_desc: '',
        egall_catg: '',
      },
        mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: '#bbb',
        }      
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtlProfile(to.params.ProfileId);
      // this.ImageDir='https://storage.googleapis.com/thira-media/performers/'+this.$route.params.ProfileId+'/';
      this.ImageDir='https://storage.googleapis.com/thiramedia/artists/perfomr'+this.$route.params.ProfileId;    
      this.componentKey=this.$route.params.ProfileId;
      // this.ReadUsersProfileMedia(this.$route.params.ProfileId)
      // this.ReadProfileCatg(this.$route.params.ProfileId);
    }
  },      
    mounted()
    {
      this.ReadDtlProfile(this.$route.params.ProfileId)
      // this.ReadUsersProfileMedia(this.$route.params.ProfileId)
      this.ImageDir='https://storage.googleapis.com/thiramedia/artists/perfomr'+this.$route.params.ProfileId;    
      // this.ReadProfileCatg(this.$route.params.ProfileId);
      // this.ReadCountArtistByCatg();
      // this.RelatedPerformers()
    },
    methods: {
      ReadDtlProfile(RecToFetch){  
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadDtlProfile',REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.DtlProfile;
        const FirstObject = resp.data.DtlProfile[0];
        this.MetaName = FirstObject.profile_name;
        this.MetaTagline = FirstObject.profile_title;
        this.MetaSeqno = FirstObject.seq_no;
        this.MetaUrl = FirstObject.profile_photo;
        // console.log('MetaName',this.MetaName)
        // console.log('MetaTagline',this.MetaTagline)
        // console.log('MetaSeqno',this.MetaSeqno)

      })
      },     
      // ReadProfileCatg(RecToFetch2){  
      //   const REQformData2 = new FormData();
      //   REQformData2.append('profile_id', RecToFetch2);
      //   REQformData2.append('profile_type', 'P');          
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadProfileCatg',REQformData2)
      //   .then((resp)=>{
      //   this.RespProfileCatg=resp.data.ProfileCatg;
      //   this.ShowSideCatg=true;

      // })
      // },
      // ReadCountArtistByCatg(){     
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadCountArtistByCatg')
      //   .then((resp)=>{
      //   this.RespProfileCnts=resp.data.CountArtistByCatg;
      // })
      // },
                
      //   RelatedPerformers(){
      //   Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RelatedPerformers')
      //   .then((resp)=>{
      //   this.RelatedData=resp.data.RelatedPerformers;
      // })
      // }, 
    //     ReadUsersProfileMedia(RecToFetch){
    //     const MEDformData = new FormData();
    //     MEDformData.append('profile_id', RecToFetch);
    //     Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadUsersProfileMedia',MEDformData)
    //     .then((resp)=>{
    //     this.RespMedia=resp.data.UsersProfileMedia;
    // })
    // },                     
    },
    computed: {     
    NotNullCatg: function(){
        return this.RespProfileCatg.filter((RespProfileCatg) => {
        return RespProfileCatg.catg_main!== null;
      });
    },  
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    float: center;
    width: 300px;
    padding: 10px 10px 10px 10px;
    height: auto;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: auto;
  /* overflow: hidden; */

}
.profile-card-5 .card-img-block img{
    float: left;
    width: 300px;
    margin-top:5px;
    padding: 10px 20px 20px 10px;
    max-height: 400px;
    border-radius: 50px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}

.profile-card-5 p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify; 
  /* display: inline-block; */
}
.tab-desc-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 450px;
  overflow: scroll;  
  /* display: inline-block; */
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
.page-title-text h6 {
    margin-top: -25px;
    color:#e75480;
    font-size:14px;
    font-weight:300;
}
.page-title-text p {
    margin-top: -25px;
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}

.medica-video-card {
    margin-top:10px;
}

.contact-card {
    margin-top:25px;
    box-shadow:0 0 1px rgba(0,0,0,0.63);
    border-radius:5px;
}
.contact-card h5{
    margin-top:20px;
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.contact-card p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.contact-card .social-links a {
    font-size: 25px;
    display: inline-block;
    /* background: #1e4356; */
    color: #e75480;
    line-height: 1;
    padding: 12px 0;
    margin-right: 4px;
    margin-bottom:30px;
    border-radius: 50%;
    border: 2px solid #72afce;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}

.contact-card .social-links a:hover {
    background: #e75480;
    color: #fff;
    text-decoration: none;
}

img {

  margin: 1px;
  border-radius: 15px;
  max-width: 450px;
  max-height: 400px;
}


</style>
